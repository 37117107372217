import { Tutorials, TutorialCfg } from '../types';
import { AppController } from '../../Controllers/AppController';
import { getInitialTutorialSteps } from './initialTutorialSteps';
import { tutorialModules } from '../modules';
import { WaitForEndOfEnergy } from '../modules/energy/WaitForEndOfEnergy';
import { OutOfEnergyShareBtn } from '../modules/energy/OutOfEnergyShareBtn';

export const tutorials: Record<Tutorials, (app: AppController) => TutorialCfg> =
  {
    [Tutorials.InitialTutorial]: (app) => {
      const steps = getInitialTutorialSteps(app);
      return {
        id: Tutorials.InitialTutorial,
        steps,
        onComplete: () => {},
        lastStep: steps.length - 1,
      };
    },
    [Tutorials.EnergyTutorial]: (app) => {
      const steps = [new WaitForEndOfEnergy(app), new OutOfEnergyShareBtn(app)];
      return {
        id: Tutorials.EnergyTutorial,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
      };
    },
    [Tutorials.SlideshowTutorial]: (app) => {
      const steps = [
        new tutorialModules.SlideshowStart(app),
        new tutorialModules.SlideshowEnd(app),
        new tutorialModules.Grant100State(app),
      ];
      return {
        id: Tutorials.SlideshowTutorial,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
      };
    },
    [Tutorials.SlideshowTradingCreateToken]: (app) => {
      const steps = [new tutorialModules.SlideshowStart(app)];
      return {
        id: Tutorials.SlideshowTradingCreateToken,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
        repeateable: true,
      };
    },
    [Tutorials.SlideshowSeasonKickOff]: (app) => {
      const steps = [new tutorialModules.SlideshowStart(app)];
      return {
        id: Tutorials.SlideshowSeasonKickOff,
        steps,
        onComplete: () => {
          app.replicant.invoke.kickOffNewSeason();
        },
        lastStep: steps.length,
        transparentBg: true,
        repeateable: true,
      };
    },
    [Tutorials.SlideshowTiktok]: (app) => {
      const steps = [new tutorialModules.SlideshowStart(app)];
      return {
        id: Tutorials.SlideshowTiktok,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
        repeateable: true,
      };
    },
    [Tutorials.SlideshowTiktok2]: (app) => {
      const steps = [new tutorialModules.SlideshowStart(app)];
      return {
        id: Tutorials.SlideshowTiktok2,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
        repeateable: true,
      };
    },
    [Tutorials.SlideshowTiktokOnlyExperience]: (app) => {
      const steps = [new tutorialModules.SlideshowStart(app)];
      return {
        id: Tutorials.SlideshowTiktokOnlyExperience,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
        repeateable: true,
      };
    },
  };

export const getFTUE = (app: AppController) => {
  const isTikTokOnlyExperience = app.getIsInAB(
    'TEST_TIKTOK_ONLY',
    'tiktok_only_experience',
  );

  const tutorial = isTikTokOnlyExperience
    ? Tutorials.SlideshowTiktokOnlyExperience
    : undefined;
  return tutorial;
};
