import './TiktokTapGame.scss';
import { assets } from '../../../../assets/assets';
import { isDesktop } from '../../../../data/device';
import { app } from '../../../../data/Controllers/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../../data/hooks';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { tiktokGameSessionDuration } from '../../../../data/utils';
import { t } from 'i18next';

// ==============================================================
// #region TapGame

// const buttonPlayLayout = 'center'; // center | side

interface TiktokTapGameProps {
  visible: boolean;
  canSwipe: boolean;
  uiAlpha: number;
  canShowTapGame: boolean;
  onJiggle: () => void;
}

export const TiktokTapGame: React.FC<TiktokTapGameProps> = ({
  visible,
  canSwipe,
  uiAlpha,
  canShowTapGame,
  onJiggle,
}) => {
  useManyAppUpdates({
    id: 'TiktokTapGame',
    events: [
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingGameTick),
      },
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingUpdate),
      },
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingTap),
      },
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingPlayingUpdate),
      },
      {
        listener: app.memes.attachEventListener(MemesEvents.OnTokenSelected),
      },
    ],
  });

  const { token } = app.memes.currentMeme;
  const { tap, points, myKickback } = app.tmg;

  if (!token || !tap) {
    return null;
  }

  const {
    onTap,
    showPoints,
    taps,
    tapEnabled,
    isPlaying,
    gameStartCountdown,
    showOverlay,
  } = tap;

  const onHandleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // console.warn('>>> onHandleClick');
    if (!tapEnabled) {
      return;
    }
    const pos = { x: event.clientX, y: event.clientY };
    onExecuteTap(pos);
  };

  const onHandleTap = (event: React.TouchEvent<HTMLDivElement>) => {
    // console.warn('>>> onHandleTap');
    if (!tapEnabled) {
      return;
    }
    const pos = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    onExecuteTap(pos);
  };

  const onExecuteTap = (mousePos: { x: number; y: number }) => {
    // console.warn('>>> executeTap');
    if (!canSwipe) return;

    onTap(mousePos);
    onJiggle();
  };

  const useClicks = isDesktop();

  const isCountdownEnabled = !!gameStartCountdown; // gameStartCountdown === undefined

  // should we render finger tip overlay?
  // only if game is active and 2 seconds passed without user interaction
  const isOverlayEnabled = visible && showOverlay;
  let overlayFadeClass = isOverlayEnabled ? 'fade-in-slow' : 'fade-out-slow';
  if (!showOverlay || !canShowTapGame) overlayFadeClass = 'invisible';

  // should we render big points label
  // by new specs, points are always visible now
  const isPointsEnabled = visible && isPlaying && !isCountdownEnabled;
  let pointsFadeClass = isPointsEnabled ? 'fade-in-slow' : 'invisible';

  // should we render timer?
  // only if game is active
  const isTimerEnabled = visible && showPoints && !isCountdownEnabled;
  let timerFadeClass = isTimerEnabled ? 'fade-in-slow' : 'invisible';
  if (!showPoints || !canShowTapGame) timerFadeClass = 'invisible';

  // render hand at a scale that is relative to screen height
  // so is smaller on smaller devices
  const handScale = Math.min(window.innerHeight / 850, 1);

  // note that we need to set z-index to 4 or otherwise tap-ui will be under the normal ui mask

  return (
    <div
      id={`tiktok-tap-game`}
      className={`tiktok-tap-game`}
      style={{ opacity: uiAlpha }}
    >
      {/* game tappable area with touch events */}
      {tapEnabled && (
        <div
          id="tap-area"
          className={`tap-area ${visible ? 'visible' : ''}`}
          onTouchStart={useClicks ? undefined : onHandleTap}
          onClick={useClicks ? onHandleClick : undefined}
        ></div>
      )}

      {/* ui area with click events */}
      <div className={`tiktok-tap-game-ui`}>
        {/* overlay */}
        <div className={`tap-to-earn`}>
          <div
            className={`tap-to-earn-content ${overlayFadeClass}`}
            style={{ transform: `scale(${handScale})` }}
          >
            <img src={assets.tap_to_earn} alt="Tap to earn" />
          </div>
        </div>

        {/* topUI : timer - points - playButton */}
        <div className={`top-ui ${pointsFadeClass}`}>
          {/* timer */}
          <Timer fadeClass={timerFadeClass} />

          {/* points */}
          <div className="points-circle">
            <span className="points-value">{taps}</span>
          </div>
          <span className="points-label">{t('tapgame_yourscore')}</span>
        </div>
      </div>

      {/* countdown 3 2 1 */}
      {isCountdownEnabled && (
        <div
          className={`countdown-container ${
            gameStartCountdown ? '' : 'invisible'
          }`}
        >
          <div className={`countdown ${gameStartCountdown ? '' : 'invisible'}`}>
            {gameStartCountdown}
          </div>
        </div>
      )}

      {/* This element is hidden, only used to copy the ripple within programatically */}
      {/* Note: limiting this to one ripple circle per tap. Feels cleaner and for performance reasons */}
      <div id={`ripple-templates-tiktok`} style={{ top: '0px' }}>
        <div className="ripple-circles-tiktok">
          <div className="ripple-circle-1"></div>
        </div>
      </div>
    </div>
  );
};

// ==============================================================
// #region Timer

interface TimerProps {
  fadeClass: string;
}

const Timer = ({ fadeClass }: TimerProps) => {
  const { tap } = app.tmg;

  if (!tap) {
    return;
  }

  const totalTime =
    app.tmg.tap?.sessionDuration ?? tiktokGameSessionDuration.control;

  const percent = (tap.timeLeft * 100) / totalTime;

  const timeLeft = `00:${tap.timeLeft < 10 ? 0 : ''}${tap.timeLeft}`;

  return (
    <div className={`timer-container ${fadeClass}`}>
      <img src={assets.tap_timer_frame} />

      <div className="timer-circle">
        <div className="progress">
          {/* pie-chart */}
          <svg viewBox="0 0 64 64" className="pie">
            <circle
              className="pie-chart"
              r="25%"
              cx="50%"
              cy="50%"
              strokeDasharray={`${percent} 100`}
            ></circle>
          </svg>
        </div>
      </div>
      <div className="timer-time">
        <div className="label">{timeLeft}</div>
      </div>
    </div>
  );
};

// ==============================================================
