import {
  getBalance,
  getEnergy,
  getHasCompletedDailyCode,
  getLeague,
} from '../../../replicant/features/game/game.getters';
import {
  League,
  LeagueToTitle,
  SEASON,
  SEASON_LEAGUE_REWARDS,
} from '../../../replicant/features/game/ruleset/league';
import { app, AppController } from '../AppController';
import { View } from '../../View';
import { AppColor, Fn, Optional } from '../../types';
import { isTiktokEnabled, rollingNumberValue, waitFor } from '../../utils';
import {
  AirtableQuestItem,
  Quest,
} from '../../../replicant/features/quests/types';
import { TxType } from '../../../replicant/features/offchainTrading/types';
import { TradingToken } from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { ModalManager } from './ModalManager';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import {
  Slideshow,
  slideshows,
} from '../../../components/Tutorial/Slideshows/config';
import { assets } from '../../../assets/assets';
import Big from 'big.js';
import { t } from 'i18next';
import {
  getQuest,
  getUnpromotedQuests,
} from '../../../replicant/features/quests/getters';
import { LabelManager, LabelManagerKeys } from './LabelManager';
import { initModalList } from './initModalList';
import {
  ElementUIState,
  ProfileTab,
  UIEvents,
  SlideshowCfg,
  PopupOpts,
  DrawerOpts,
  Tooltip,
  ConfettiOpts,
  BADGE_VALUES,
} from './UITypes';
import { BusinessController } from '../BusinessController';
import { gameApi } from '../../api';
import { RESET_SEASON_2_LABEL } from '../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { ModalLabels } from '../../../replicant/ruleset';
import { qpConfig } from '../../config';
import { Tutorials } from '../../tutorial/types';

const initialState = {
  clicker: {
    outOfViewOverlay: ElementUIState.Normal,
    footer: ElementUIState.Normal,
    energyGauge: ElementUIState.Normal,

    btnLeague: ElementUIState.Normal,
    btnFriends: ElementUIState.Normal,
    btnMine: ElementUIState.Normal,
    btnEarn: ElementUIState.Normal,
    btnBooster: ElementUIState.Normal,
    btnTeam: ElementUIState.Normal,
    btnClicker: ElementUIState.Normal,

    dailyCode: ElementUIState.Remove,
    btnDailyReward: ElementUIState.Normal,
    btnDailyCode: ElementUIState.Normal,
    btnDailyCombo: ElementUIState.Normal,
  },
  shop: {
    btnRocket: ElementUIState.Normal,
    btnEnergy: ElementUIState.Normal,
    cards: ElementUIState.Normal,
  },
  mine: {
    tutorialDrawer: ElementUIState.Normal,
    btnTab: ElementUIState.Normal,
    tutorialOverlay: ElementUIState.Normal,
  },
  earn: {
    questButtonsNonTutorial: ElementUIState.Normal,
  },
  cards: {} as Record<string, ElementUIState>,
  tutorial: {
    slideshow: false,
    energyLimit: undefined as Optional<number>,
    league: undefined as Optional<string>,
    outOfEnergyShare: false,
    badges: {
      mine: undefined as Optional<number>,
      earn: undefined as Optional<number>,
      friends: undefined as Optional<number>,
      boosters: undefined as Optional<number>,
      trading: undefined as Optional<number | string>,
    },
  },
  profile: {
    ownedOrCreatedOrFarming: 'Owned' as ProfileTab,
  },
  suspendInitialModals: false,
  tapping: {
    points: undefined as Optional<string | 'loading'>,
  },
};

export class UIController extends BusinessController<UIEvents, undefined> {
  private states = initialState;

  private _spinnerActive = false;
  get spinnerActive() {
    return this._spinnerActive;
  }
  public showSpinner() {
    this._spinnerActive = true;
    this.sendEvents(UIEvents.OnSpinnerUpdate);
  }
  public hideSpinner() {
    this._spinnerActive = false;
    this.sendEvents(UIEvents.OnSpinnerUpdate);
  }

  get state() {
    return {
      ...this.states,
    };
  }

  public getCardState = (id: string) => {
    return this.state.cards[id] || ElementUIState.Normal;
  };

  // ---------------------------

  private _footerExanded: boolean = false;
  get footerExpanded() {
    return this._footerExanded;
  }
  public setFooterExpanded(value: boolean) {
    this._footerExanded = value;
    this.sendEvents(UIEvents.OnFooterExpanded);
  }

  // ---------------------------

  private animatedBalance: number = -1;
  get balance() {
    if (this.animatedBalance > 0) {
      return this.animatedBalance;
    }
    return getBalance(this.app.state, this.app.now());
  }

  public tutorialEnergyLimit?: number;
  get energyLimit() {
    return this.state.tutorial.energyLimit ?? this.app.clicker.energyLimit;
  }

  get energy() {
    return Math.min(
      getEnergy(this.app.state, this.app.now()),
      this.energyLimit,
    );
  }

  private _slideshow?: SlideshowCfg;

  get slideshow() {
    if (!this._slideshow) {
      return undefined;
    }
    const { index, items, tutorial } = this._slideshow;

    return {
      tutorial,
      slide: items[index],
      isLast: index === items.length - 1,
      items,
    };
  }

  get league() {
    return getLeague(this.app.replicant.state);
  }

  get appColor() {
    if (this.app.clicker.morseCodeMode) {
      return AppColor.Purple;
    }
    if (this.app.clicker.bonus) {
      return AppColor.Purple;
    }

    return `league-${this.getLeagueTitle()}`;
  }

  get questStatuses() {
    return this.app.state.quests;
  }

  get minLoadScreenTime() {
    return 0;
  }

  get isShowingOutOfEnergyInviteTutorial() {
    return this.state.tutorial.outOfEnergyShare;
  }

  get badges() {
    // these are for bucket tests
    const tutorialBadges = this.state.tutorial.badges;
    const stateBadges = this.app.state.badgeControl;
    const badges = !this.app.realFirstEverSession
      ? {
          mine: stateBadges.cards.length,
          earn: stateBadges.earn.length,
          friends: stateBadges.friends.length,
          boosters: stateBadges.boosters.length,
          trading: BADGE_VALUES.TRADING_ALERT,
        }
      : {
          mine: 0,
          earn: 0,
          friends: 0,
          boosters: 0,
          trading: BADGE_VALUES.TRADING_ALERT,
        };
    return {
      mine: tutorialBadges.mine ?? badges.mine,
      earn: tutorialBadges.earn ?? badges.earn,
      friends: tutorialBadges.friends ?? badges.friends,
      boosters: tutorialBadges.boosters ?? badges.boosters,
      trading: tutorialBadges.trading ?? badges.trading,
    };
  }

  private _powerupCardsBadgesState = {} as any;
  setPowerupCardsBadgesStateChecked = (category: string, ids: string[]) => {
    ids.forEach((id) => {
      this._powerupCardsBadgesState[id] = true;
    });
    this._powerupCardsBadgesState[category] = true;
  };
  get powerupCardsBadgesState() {
    return this._powerupCardsBadgesState;
  }

  private suspendInitialModalsResolver!: Fn<void>;
  public waitForInitialModalsSuspense = new Promise((resolve) => {
    this.suspendInitialModalsResolver = resolve;
  });

  public readonly popup = new ModalManager<PopupOpts>(this.app);

  public readonly drawer = new ModalManager<DrawerOpts>(this.app);

  public readonly tooltip = new View<Tooltip>('tooltip', this.app, async () => {
    return {
      text: 'test',
      origin: { x: '0', y: '0' },
      offset: { x: '0', y: '0' },
    };
  });

  public readonly confetti = new View<ConfettiOpts>(
    'confetti',
    this.app,
    async () => {
      return {
        gravity: {
          from: 0.3,
          to: 0.5,
        },
      };
    },
  );

  private labelManager = new LabelManager(this.app, LabelManagerKeys.UILabels);

  public init = async () => {
    this.labelManager.init(initModalList);

    // decide if to display daily code completed state
    if (getHasCompletedDailyCode(this.app.state)) {
      this.setClickerUIState({ btnDailyCode: ElementUIState.Complete });
    }

    // decide if to display badges
    if (!this.app.realFirstEverSession && !this.app.tutorial.active) {
      await gameApi.addBadges();
    }

    this.handleAppInitModals();

    this.onInitComplete();
  };

  private handleAppInitModals = async () => {
    if (
      this.app.criticalError ||
      this.app.tutorial.active ||
      qpConfig.skipInitModals
    ) {
      return;
    }

    await waitFor(500);

    if (this.state.suspendInitialModals) {
      await this.waitForInitialModalsSuspense;
    }

    // show season kick-off before any drawer
    if (this.app.state.season !== SEASON) {
      const previousSeason = SEASON - 1;
      const previousSeasonIdx = previousSeason - 1;
      const previousSeasonScore =
        this.app.state.seasonScores[previousSeasonIdx];
      const previousSeasonLeague = this.app.state.seasonLeagues[
        previousSeasonIdx
      ] as League;

      if (previousSeasonLeague) {
        const reward =
          SEASON_LEAGUE_REWARDS[previousSeason][previousSeasonLeague];
        await this.app.tutorial.startTutorial(
          Tutorials.SlideshowSeasonKickOff,
          {
            substitutes: {
              season: SEASON.toString(),
              previousSeason: previousSeason.toString(),
              score: previousSeasonScore!.toString(),
              reward: largeIntegerToLetter(reward),
            },
          },
        );
      }
    } else {
      if (this.app.state.labels.includes(RESET_SEASON_2_LABEL)) {
        this.drawer.show({
          id: 'generic',
          opts: {
            title: t('drawer_s2_reset_title'),
            image: assets.sloth_reporter,
            subtitle: `${t('drawer_s2_reset_description')}\n${t(
              'drawer_s2_reset_reward',
            )}`,
            buttons: [
              {
                cta: t('drawer_s2_reset_cta'),
                onClick: () => {
                  this.app.invoke.removeLabels({
                    labels: [RESET_SEASON_2_LABEL],
                  });
                  this.drawer.close();
                },
              },
            ],
          },
        });
      }
    }

    if (
      this.app.state.labels.includes(ModalLabels.SHOW_MEME_TOKEN_GIFT_MODAL) &&
      this.app.state.trading.giftTokenId
    ) {
      this.app.asyncGetters
        .getOffchainTokensFromOpenSearch({
          offchainTokenIds: [this.app.state.trading.giftTokenId],
        })
        .then(([token]) => {
          if (!token) {
            return;
          }

          // meme_token_gift_1_description
          const tKeyPrefix = `meme_token_gift_3_`;

          this.drawer.show({
            id: 'generic',
            opts: {
              title: t(`${tKeyPrefix}title`),
              image: token.profile.image,
              subtitle: t(`${tKeyPrefix}description`, {
                tokenName: token.profile.name,
              }),
              buttons: [
                {
                  cta: t('trading_coming_soon_button'),
                  onClick: () => {
                    this.app.invoke.removeLabels({
                      labels: [ModalLabels.SHOW_MEME_TOKEN_GIFT_MODAL],
                    });
                    this.drawer.close();
                    app.nav.goTo('TradingPage');
                  },
                },
              ],
            },
          });
        });
    }

    if (this.app.isFirstSession) {
      if (this.app.clicker.cardPromo) {
        this.drawer.show({ id: 'minePromo' });
      } else {
        this.drawer.show({ id: 'welcome' });
      }
    }
    if (this.app.clicker.expiredGift) {
      this.drawer.show({ id: 'mineGiftExpired' });
    }
    if (
      !app.isFirstSession &&
      (this.app.clicker.botEarnings ||
        this.app.clicker.powerUpBonus ||
        this.app.clicker.unclaimedReferralRewards)
    ) {
      this.drawer.show({
        id: 'rewardSummary',
        hideClose: true,
        onClose: () => {
          this.confetti.hide();
        },
      });
    }

    const unpromotedQuests = getUnpromotedQuests(
      this.app.state,
      this.app.now(),
    );
    if (!this.app.isFirstSession && unpromotedQuests.length > 0) {
      const [questConfig] = unpromotedQuests;
      this.showPromoDrawer(questConfig, 'initial');
    }

    if (!this.app.isFirstSession && this.app.clicker.cardPromo) {
      this.drawer.show({ id: 'minePromo' });
    }
    if (this.app.clicker.cardGift) {
      this.drawer.show({ id: 'mineGiftReceived' });
    }
    if (this.app.clicker.inviteDrawerDuration > 0) {
      this.drawer.show({ id: 'inviteFriend' });
    }

    if (this.app.isFirstSessionOfTheDay && this.app.clicker.getDailyAirdrop()) {
      this.drawer.show({ id: 'multipleGifts' });
    }

    // tiktok teaser 1

    if (this.app.state.labels.includes(ModalLabels.SHOW_TIKTOK_TEASER_MODAL)) {
      if (!isTiktokEnabled()) {
        this.drawer.show({
          id: 'generic',
          opts: {
            title: t('tiktok_teaser_title'),
            image: assets.tiktok_teaser,
            subtitle: `${t('tiktok_teaser_description')}`,
            subtitle2: `${t('tiktok_teaser_subtitle2')}`,
            buttons: [
              {
                cta: t('tiktok_teaser_cta'),
                onClick: () => {
                  this.drawer.close();
                },
              },
            ],
          },
        });
      }

      this.app.invoke.removeLabels({
        labels: [ModalLabels.SHOW_TIKTOK_TEASER_MODAL],
      });
    }

    const isTikTokOnlyExperience = app.getIsInAB(
      'TEST_TIKTOK_ONLY',
      'tiktok_only_experience',
    );

    // tiktok teaser 2
    const showTeaser2 =
      this.app.state.labels.includes(ModalLabels.SHOW_TIKTOK_TEASER_MODAL_2) &&
      isTiktokEnabled() &&
      !isTikTokOnlyExperience;

    if (showTeaser2) {
      this.drawer.show({
        id: 'generic',
        opts: {
          title: t('tiktok_teaser_2_title'),
          image: assets.tiktok_teaser,
          subtitle: `${t('tiktok_teaser_2_description')}`,
          isSubtitleLeft: true,
          buttons: [
            {
              cta: t('tiktok_teaser_2_cta'),
              onClick: () => {
                this.drawer.close();
              },
            },
          ],
        },
      });

      this.app.invoke.removeLabels({
        labels: [ModalLabels.SHOW_TIKTOK_TEASER_MODAL_2],
      });
    }
  };

  /**
   * @param amount the amount added to or removed from the balance
   * @param afterGrant if the state has been updated with the new balance or not yet. Default to true (update has been updated)
   */
  public animateBalance = async (amount: number, afterGrant = true) => {
    this.animatedBalance = this.app.state.balance - (afterGrant ? amount : 0);
    await rollingNumberValue(
      this.animatedBalance,
      this.animatedBalance + amount,
      (value) => {
        this.animatedBalance = value;
        this.sendEvents(UIEvents.OnBalanceUpdate);
      },
      20,
      50,
    );
    this.animatedBalance = -1;
  };

  public onAppValueUpdate = (evt: UIEvents) => {
    this.sendEvents(evt);
  };

  public getLeagueTitle = (league: League | string = this.league) => {
    return LeagueToTitle[league];
  };

  // Setters
  public setClickerUIState(update: Partial<typeof this.states.clicker>) {
    this.states.clicker = {
      ...this.states.clicker,
      ...update,
    };
    this.sendEvents(UIEvents.ClickerUpdate);
  }

  public setShopUIState(update: Partial<typeof this.states.shop>) {
    this.states.shop = {
      ...this.states.shop,
      ...update,
    };
    this.sendEvents(UIEvents.ShopUpdate);
  }

  public setMineUIState(update: Partial<typeof this.states.mine>) {
    this.states.mine = {
      ...this.states.mine,
      ...update,
    };
    this.sendEvents(UIEvents.MineUpdate);
  }

  public setEarnUIState(update: Partial<typeof this.states.earn>) {
    this.states.earn = {
      ...this.states.earn,
      ...update,
    };
    this.sendEvents(UIEvents.EarnUpdate);
  }

  public setTutorialUIState(update: Partial<typeof this.states.tutorial>) {
    this.states.tutorial = {
      ...this.states.tutorial,
      ...update,
    };
    this.sendEvents(UIEvents.TutorialUpdate);
  }

  public setProfileState(update: Partial<typeof this.states.profile>) {
    this.states.profile = {
      ...this.states.profile,
      ...update,
    };
    this.sendEvents(UIEvents.OnProfileUpdate);
  }

  public setTappingState(update: Partial<typeof this.states.tapping>) {
    this.states.tapping = {
      ...this.states.tapping,
      ...update,
    };
    this.sendEvents(UIEvents.OnTappingUpdate);
  }

  public setCardState = (id: string, state: ElementUIState) => {
    this.state.cards[id] = state;
  };

  public startSlideshow = (slideshow: Slideshow) => {
    this._slideshow = {
      index: 0,
      tutorial: slideshow,
      items: slideshows[slideshow].items,
    };
    this.sendEvents(UIEvents.TutorialUpdate);
  };

  public nextSlide = () => {
    if (this._slideshow) {
      this._slideshow.index += 1;
      this.sendEvents(UIEvents.TutorialUpdate);
    }
  };

  public suspendInitialModals = () => {
    this.state.suspendInitialModals = true;
  };

  public resumeInitialModals = () => {
    this.state.suspendInitialModals = false;
    this.suspendInitialModalsResolver();
  };

  reset = () => {
    this.states = initialState;
    this.popup.clear();
    this.drawer.clear();
  };

  onCreateOffchainTokenSuccess = (offchainToken?: TradingToken) => {
    // close offchainToken confirmation drawer and navigate to trading page
    this.drawer.close();
    console.error('onCreateOffchainTokenSuccess 1');

    if (isTiktokEnabled()) {
      // this.app.trading.setListingCategory('Hot');
      // if (offchainToken) {
      //   this.app.trading.setSelectedOffchainToken(offchainToken);
      // }
      console.error('onCreateOffchainTokenSuccess 2', offchainToken?.id);
      this.app.memes.setCurrent(
        { filter: 'Hot', tokenId: offchainToken?.id },
        { sourceCategory: 'navigation', sourceName: 'creation' },
      );
      // Make sure we reset navigation so when we press back we don't go to the form screen
      this.app.nav.goTo('TiktokPage', { wipeCrumbs: true });
    } else {
      if (offchainToken) {
        // this.app.trading.setSelectedOffchainToken(offchainToken);
        this.app.memes.setCurrent({ tokenId: offchainToken.id });
        this.app.nav.goTo('TradingTokenPage', {
          wipeCrumbs: true,
          pushParent: 'TradingPage',
        });
      } else {
        this.app.memes.setCurrent({ filter: 'New' });
        // Make sure we reset navigation so when we press back we don't go to the form screen
        this.app.nav.goTo('TradingPage', { wipeCrumbs: true });
      }
    }

    // wait and instant for all drawers to be closed
    setTimeout(() => {
      this.app.ui.hideSpinner();

      this.app.ui.confetti.show();
      let offchainTokenValue = '-';
      if (offchainToken?.buyPrice) {
        offchainTokenValue =
          offchainToken.buyPrice instanceof Big
            ? offchainToken.buyPrice.toNumber().toFixed(8).toString()
            : Number(offchainToken.buyPrice).toFixed(8).toString();
      }

      // show trading success drawer after creating a new card
      this.drawer.show({
        id: 'drawerTradingCreationSuccess',
        onClose: () => this.app.ui.confetti.hide(),
        props: {
          transactionSuccess: {
            mode: 'create',
            offchainTokenId:
              offchainToken?.id || 'offchain-token-id-placeholder',
            offchainTokenName: offchainToken?.name || 'Your offchainToken',
            offchainTokenDescription:
              offchainToken?.description.description || 'The best there is',
            offchainTokenImage: offchainToken?.image || assets.icon_camera,
            offchainTokenValue,
          },
        },
      });
    }, 350);
  };

  onOffchainTokenTxSuccess = ({
    offchainTokenId,
    offchainTokenName,
    offchainTokenDescription,
    offchainTokenImage,
    txAmount,
    txType,
  }: {
    offchainTokenId: string;
    offchainTokenName: string;
    offchainTokenDescription: string;
    offchainTokenImage: string;
    txAmount: number; // tokens for buy | coin for sell
    txType: TxType;
  }) => {
    // close offchainToken confirmation drawer and navigate to trading page
    this.drawer.close();

    const offchainTokenValue =
      txType === 'buy'
        ? txAmount.toFixed(8).toString()
        : largeIntegerToLetter(txAmount);

    // wait and instant for all drawers to be closed
    setTimeout(() => {
      this.app.ui.hideSpinner();

      // open transaction success drawer after buying/selling a card
      this.app.ui.confetti.show();

      this.drawer.show({
        id: 'drawerTradingTransactionSuccess',
        onClose: () => {
          // important: when setting timeout to 350,
          // onClose was not triggering when we actually close drawerTradingTransactionSuccess
          // instead, was happening when we close drawerTradingTransactionConfirm
          // changing the timeout to 500 solves the issue
          this.app.ui.confetti.hide();
        },
        props: {
          transactionSuccess: {
            mode: txType,
            offchainTokenId,
            offchainTokenName,
            offchainTokenDescription,
            offchainTokenImage,
            offchainTokenValue,
          },
        },
      });
    }, 500); // 350 -> see comment above
  };

  showError = ({
    title = 'Transaction Failed',
    cta = t('trading_transaction_error_button'),
    onClick = () => {},
    message,
    onClose,
  }: {
    message: string;
    title?: string;
    cta?: string;
    onClose?: Fn<void>;
    onClick?: Fn<void>;
  }) => {
    this.app.ui.hideSpinner();

    return this.app.ui.drawer.show(
      {
        id: 'errorGeneric',
        hideClose: true,
        opts: {
          title,
          subtitle: message,
          buttons: [{ cta, onClick }],
        },
      },
      true,
    );
  };

  showDebugPopup = (
    data: Record<string, any>,
    pretty = false,
    title = 'debug',
  ) => {
    this.popup.show({
      title,
      description: pretty
        ? JSON.stringify(data, null, 2)
        : JSON.stringify(data),
      cta: 'close',
      onClick: () => this.app.ui.popup.close(),
    });
  };

  showPromoDrawer = (
    questInput: Quest | AirtableQuestItem,
    initial: Optional<'initial'> = undefined,
  ) => {
    const quest = getQuest(questInput.id);
    if (!quest) {
      return;
    }
    const hasBeenChecked = this.app.session.hasQuestBeenChecked(quest.id);
    const isQuestVerifying = this.app.session.isQuestVerifying(quest.id);

    let cta = t(quest.actionCTA);
    let url = quest.url ?? undefined;

    if (
      hasBeenChecked &&
      this.app.state.quests[quest.id].state !== 'complete'
    ) {
      cta = t('claim_reward');
      url = undefined;
    }

    const baseProps = {
      image: quest.iconUrl || assets.friends,
      title: t(quest.title),
      score: quest.reward.toString(),
      footnote:
        'Wait for the verification to complete to claim the reward (it may take a few seconds)',
    };

    const buttons = [
      {
        cta,
        url,
        onClick: async () => {
          if (!hasBeenChecked) {
            if (initial) {
              this.app.track('PressGoToQuest', {
                page: 'drawer_quest_promo',
                quest_name: quest.analytics,
              });
            }

            this.app.invoke.setQuestAsPromoted({ questId: quest.id });
            this.drawer.updateCurrentOpenModalView({
              opts: {
                ...baseProps,
                buttons: [
                  {
                    cta: 'Verifying...',
                    onClick: () => {},
                  },
                ],
              },
            });
            this.app.session.onQuestChecked(quest.id);

            setTimeout(() => {
              this.drawer.updateCurrentOpenModalView({
                opts: {
                  ...baseProps,
                  buttons: [
                    {
                      cta: t('claim_reward'),
                      onClick: async () => {
                        const response = await this.app.invoke.updateQuest({
                          questId: quest.id,
                        });
                        if (response?.data === 'complete') {
                          this.app.track(
                            'quest_verification_dialog_quest_complete',
                            {
                              quest_name: quest.analytics,
                              amount: quest.reward,
                            },
                          );
                          // Make sure parent component updates too
                          this.app.views.EarnPage.rerender();
                          this.app.ui.confetti
                            .setData({
                              gravity: { from: 0.5, to: 0.9 },
                              duration: 1500,
                            })
                            .show(false);
                          await waitFor(1000);
                        }
                        this.drawer.close();
                      },
                    },
                  ],
                },
              });
            }, 10000);
            return;
          }
        },
      },
    ];

    this.drawer.show(
      {
        id: 'generic',
        opts: { ...baseProps, buttons },
      },
      !initial,
    );
  };
}
